import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTwitter, faGithub, faReddit, faTelegram, faDiscord, faMedium, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Images } from '../../assets/asset';
import { ENV } from './../../config/config';
import './footer.css';

const Footer = () => {
    let pathname = ""

    const goToUrl = (url) => {
        window.open(url);
    } 
  return (
    <>
    <footer id="myntist-footer" className="position-relative">
        <div className="top-footer">
            <Container fluid>
                <Row>
                    <Col xl={4} md={6}>
                        <strong className="footer-logo mb-4 d-inline-block align-top">
                            <Link className="d-inline-block align-top" to="/">
                                <img src={Images.myntistLogo} alt="Site Logo" />
                            </Link>
                        </strong>
                        <div className="about-hex text-white">
                            <p>Myntist is the future. Our complex dcommerce marketplace allows for crypto investments, the transfer of physical goods, non-fungible tokens (NFTs) and decentralized finance (DeFi) and we’re accessible globally!</p>
                        </div>
                    </Col>
                    <Col xl={4} md={6}>
                        <Row>
                            <Col sm={12}>
                                <div className="footer-nav-holder">
                                    <h3 className="fw-bold text-white">Platforms</h3>
                                    <ul className="list-unstyled footer-nav">
                                        {/* <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/explore-all' ? 'active' : ''} `} to="/explore-all">All NFTs</Link></li> */}
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={ENV.tokenStakingUrl}>Myntist Token and Staking</a></li>
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={ENV.fundraiserNftUrl}>Myntist NFT Fund Raising</a></li>
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={ENV.nftMarketplaceUrl}>Myntist NFT Marketplace</a></li>
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={ENV.gamificationUrl}>Myntist Gamification &amp; Treasure Box</a></li>
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={"/"}>Myntist Physical Goods</a></li>
                                        <li><a className={`position-relative transition ${pathname?.toLowerCase() === '/' ? 'active' : ''} `} target="_blank" href={"/"}>Myntist Video Streaming NFT</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={6}>
                    <div className="footer-widget">
                            <h3 className="fw-bold text-white mb-4">Join the Community</h3>
                            <ul className="list-unstyled social-links d-flex">
                                    
                                            <li className="facebook">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faFacebookF} /></a>
                                            </li> 
                                            <li className="medium">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMedium} /></a>
                                            </li> 
                                            <li className="linkedin">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </li> 
                                            <li className="instagram">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                            </li> 
                                            <li className="youtube">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                                            </li> 
                                            <li className="twitter">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                                            </li> 
                                            <li className="discord">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDiscord} /></a>
                                            </li> 
                                            <li className="reddit">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faReddit} /></a>
                                            </li> 
                                            <li className="github">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
                                            </li> 
                                            <li className="telegram">
                                                <a className="d-flex justify-content-center align-items-center rounded-circle transition" href="" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTelegram} /></a>
                                            </li> 
                                </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="bottom-footer position-relative">
            <Container>
                <div className="copyright-text text-center text-white">
                    <p className="mb-0">All Rights Reserved by Myntist</p>
                </div>
            </Container>
        </div>
    </footer>
</>
  )
}

export default Footer