import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/asset';
import { ENV } from './../../config/config';
import './fund-raising.css';
import Tilt from 'react-tilt'

const FundRaising = () => {

    const goToUrl = (url) => {
        window.open(url);
    }
    return (
        <div className='fund-raising'>
            <Row className='mb-4'>
                <Col lg={8} className="mb-4 mb-lg-0">
                    <div className='fund-raising-img'>
                        <Tilt className="Tilt" options={{ max: 10 }} >
                            <div className="Tilt-inner">
                                <img className='img-fluid' src={Images.fundRaisingOne} alt="" />
                            </div>
                        </Tilt>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="fund-raising-data h-100">
                        <span className='banner-head'>MYNTIST TOKEN AND STAKING</span>
                        <p>Vivamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris. Aenean scelerisque, sem eu dictum commodo, velit nisi blandit magna, quis scelerisque ipsum lectus ut libero. Sed elit diam, dignissim ac congue quis, aliquam in purus. Aenean scelerisque, sem eu dictum commodo, velit nisi blandit magna, quis scelerisque ipsum lectus ut libero.</p>
                        <button className='btn-filled transition' onClick={() => goToUrl(ENV.tokenStakingUrl)}><span className="d-block transition">Go to Page</span></button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={4} className="pe-lg-0 mb-4 mb-lg-0">
                    <div className='fund-raising-data-2'>
                        {/* <div className="small-img">
                            <img className='img-fluid' src={Images.fundRaisingSmall} alt="" />
                        </div> */}
                        <div className='main-data'>
                            <span className="main-data-tag">Myntist NFT Collection</span>
                            <button className='btn-filled transition' onClick={() => goToUrl(ENV.fundraiserNftUrl)}><span className="d-block transition">Go to Page</span></button>
                        </div>
                    </div>
                </Col>
                <Col lg={8} className="ps-lg-0">
                    <div className='fund-raising-img-2'>
                        <img className='img-fluid' src={Images.fundRaisingTwo} alt="" />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default FundRaising