import React, { useState, useEffect } from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import MainBanner from '../components/main-banner/main-banner'
import FundRaising from '../components/fund-raiser/fund-raising'
import Marketplace from '../components/marketplace/marketplace'
import Gamification from '../components/gamification/gamification'
import FullPageLoader from '../components/loaders/full-page-loader'

export default function Template() {

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
       setLoader(false); 
    }, 2000)
  }, [])

    return (
      <>
        {loader && <FullPageLoader />}
        <Header />
        <MainBanner />
        <FundRaising />
        <div className="single-page-center">
           <Marketplace />
           <Gamification />
        </div>
        <Footer />
    </>
  )
}
