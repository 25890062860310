import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/asset';
import Lottie from 'react-lottie';
import animationData from '../../static/7238491.json';
import { ENV } from './../../config/config';
import Tilt from 'react-tilt'

import './gamification.css';

const Gamification = () => {

    const goToUrl = (url) => {
        window.open(url);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='gamification-section'>
            <Row className='mb-4'>
                <Col md={12}>
                    <div className='gamification-div'>
                        <Row className="align-items-center">
                            <Col md={5}>
                                <div className="gamification-data">
                                    <span className="gamification-tag">
                                        MYNTIST GAMIFICATION &amp; TREASURE BOX
                                    </span>
                                    <button className='btn-filled transition' onClick={() => goToUrl(ENV.gamificationUrl)}><span className="d-block transition">Go to Page</span></button>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="gamification-img">
                                    <img src={Images.gamificationImg} className="img-fluid" alt="" />
                                </div>
                            </Col>
                        </Row>
                        {/* <div className='gamification-lottie'>
                            <Lottie
                                options={defaultOptions}
                                height={336}
                                width={336}
                                className="main-lottie"
                            />
                        </div> */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={8} className="mb-4 mb-lg-0">
                    <div className='physical-goods'>
                        <div className='physical-uper'>
                            <span className="physical-tag">MYNTIST PHYSICAL GOODS</span>
                            <button className='btn-filled transition'><span className="d-block transition">Go to Page</span></button>
                        </div>
                        <div className="physical-bg">
                            <Tilt className="Tilt" options={{ max: 10 }} >
                                <div className="Tilt-inner">
                                    <img src={Images.physicalImg} className="img-fluid" alt="" />
                                </div>
                            </Tilt>
                        </div>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="coming-soon">
                        <div className="coming-soon-bg">
                            <img src={Images.nftVideo} className="img-fluid" alt="" />
                        </div>
                        <div className="coming-soon-data">
                            <span className='coming-head'>MYNTIST VIDEO STREAMING NFT</span>
                            <div className='text-center'>
                                <span className='coming-tag'>COMING SOON</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Gamification