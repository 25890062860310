const cdnBaseUrl = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/"

const siteLogo = `${cdnBaseUrl}v1652166289/hex-nft/assets/logo_js4k0h.svg`;
const siteLogoM = `${cdnBaseUrl}v1655727474/hex-nft/assets/mynist_s1vmud.png`;
const iconSearch = `${cdnBaseUrl}v1652166671/hex-nft/assets/icon-search_b7dy65.svg`;
const bannerImg = `${cdnBaseUrl}v1655727483/hex-nft/assets/bg-banner-img_gpjreo.png`;
const gamingIcon = `${cdnBaseUrl}v1654003918/hex-nft/assets/gaming-logo_ef60f5.svg`;
const goldenBg  = `${cdnBaseUrl}v1654081602/hex-nft/assets/golden-bg-2_nixwcf.png`;   
const myntistLogo  = `${cdnBaseUrl}v1654510130/hex-nft/assets/myntist-logo_fnnuqb.png`;
const blueSearch  = `${cdnBaseUrl}v1654511165/hex-nft/assets/blue-search_jqi56l.png`;  
const cyanSearch  = `${cdnBaseUrl}v1657717903/hex-nft/assets/cyan-search_g1bcta.png`; 
const whiteSearch  = `${cdnBaseUrl}v1654511165/hex-nft/assets/white-search_jbj4tt.png`;
const uploadIcon  = `${cdnBaseUrl}v1654522861/hex-nft/assets/upload-icon_qvj33i.png`; 
const ethIcon = `${cdnBaseUrl}v1657091124/hex-nft/assets/Icon-ethereum_paddbm.png`;
const iconCreateWallet = `${cdnBaseUrl}v1657094615/hex-nft/assets/wallet_fp7rnb.png`;
const iconAddNft = `${cdnBaseUrl}v1657094615/hex-nft/assets/add-nft_vz0qos.png`;
const iconCreateNft = `${cdnBaseUrl}v1657094615/hex-nft/assets/sell-nft_k3qqrn.png`;
const statusIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/status_hzeqac.png`; 
const statusIconNew = `${cdnBaseUrl}v1657119649/hex-nft/assets/status-new_f1ms9s.png`; 
const priceIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/price_cgmyi5.png`; 
const priceIconNew = `${cdnBaseUrl}v1657119650/hex-nft/assets/price-new_iplqqa.png`; 
const collectionIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/collection_ydfnbh.png`; 
const collectionIconNew = `${cdnBaseUrl}v1657119650/hex-nft/assets/collection-new_kwcbmf.png`; 
const categoryIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/category_c8legh.png`;  
const categoryIconNew = `${cdnBaseUrl}v1657119649/hex-nft/assets/category-new_zkoypd.png`; 
const authorIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/author_uvzrse.png`;  
const authorIconNew = `${cdnBaseUrl}v1657119651/hex-nft/assets/author-new_mv838r.png`; 
const favIcon = `${cdnBaseUrl}v1655727474/hex-nft/assets/mynist_s1vmud.png`; 

const singleBanner = `${cdnBaseUrl}v1660743450/hex-nft/assets/single-banner-img_uv1dsk_ednqzm.png`; 
const fundRaisingOne = `${cdnBaseUrl}v1660743449/hex-nft/assets/6089017_2x_hibuow_yeqxgu.png`;
const fundRaisingTwo = `${cdnBaseUrl}v1660743450/hex-nft/assets/Group_490_2x_phl4p3_esatcx.png`;
const fundRaisingSmall = `${cdnBaseUrl}v1660743450/hex-nft/assets/myntist-small-bg_j8mk7f_rwj3oa.png`;
const marketplaceImg = `${cdnBaseUrl}v1660743452/hex-nft/assets/single-marketplace_tvmrnr_khdxm2.png`;
const gamificationImg = `${cdnBaseUrl}v1660743448/hex-nft/assets/Mask_Group_7_2x_shhuex_rkudhk.png`;
const physicalImg = `${cdnBaseUrl}v1660743454/hex-nft/assets/4305626_2x_n0nl8q_cnj6ty.png`;
const nftVideo = `${cdnBaseUrl}v1660743449/hex-nft/assets/Index_dark_2x_bjlqu4_prqxs0.png`;


export const Images = {  
	siteLogo,
	iconSearch,
	bannerImg,
	iconCreateWallet,
	iconAddNft,
	iconCreateNft,
	gamingIcon,
	goldenBg,
	myntistLogo,
	blueSearch,
	whiteSearch,
	uploadIcon,
	ethIcon,
	statusIcon,
	statusIconNew,
	priceIcon,
	priceIconNew,
	collectionIcon,
	collectionIconNew,
	categoryIcon,
	categoryIconNew,
	authorIcon,
	authorIconNew,
	siteLogoM,
	cyanSearch,
	favIcon,
	singleBanner,
	fundRaisingOne,
	fundRaisingTwo,
	fundRaisingSmall,
	marketplaceImg,
	gamificationImg,
	physicalImg,
	nftVideo
}