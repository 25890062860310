import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/asset';

import './main-banner.css';

const MainBanner = () => {
    return (
        <div className='main-banner'>
            <Row>
                <Col md={6}>
                    <div className='single-banner-data'>
                        <span className='banner-head'>MYNTIST</span>
                        <h1>INFINITE ASSETS</h1>
                        <p>NFT fundraiser release is to cultivate an initial community of high quality early access investors to act as an initial community for Myntist</p>
                        <button className='btn-filled transition'><span className="d-block transition">START EXPLORE NOW</span></button>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="position-relative w-100 h-100">
                        <div className='single-banner-img'>
                            <img className='img-fluid' src={Images.singleBanner} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default MainBanner