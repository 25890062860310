import React, { useState } from 'react'
import { Images } from '../../assets/asset';
import { Link } from 'react-router-dom'
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import './header.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Header() {
    const [input, setInput] = useState(false);
    const searchHandler = () => {
        setInput(!input)
    }
  return (
    <>
                <header id="myntist-header" className="header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <strong className="logo d-inline-block align-top">
                        <Link to="/" className="d-inline-block align-top">
                            <LazyLoadImage className="img-fluid" /* effect = 'blur' */ src={Images.myntistLogo} alt="Site Logo" />
                        </Link>
                    </strong>
                </div>
                <div className="d-flex justify-content-end">
                    <Navbar expand="lg" className="pos-stat justify-content-end p-0 myntist-navbar" >
                        <span className="search-span">
                            <form className="search-form transition">
                                <input type="text" id="site-search" placeholder="Enter to Search" className={`transition  ${input ? 'input-width' : 'input-no-width'}`} aria-label="Search through site content"  />
                                <span id="search-icon-header" className={`search-icon-inner  ${input ? 'active' : ''} `} onClick={() => searchHandler()}>
                                    <LazyLoadImage className="img-fluid white-logo" /* effect = 'blur' */ src={Images.whiteSearch} alt="" />
                                    <LazyLoadImage className="img-fluid blue-logo" /* effect = 'blur' */ src={Images.blueSearch} alt="" />
                                    <LazyLoadImage className="img-fluid cyan-logo" /* effect = 'blur' */ src={Images.cyanSearch} alt="" />
                                </span>
                            </form>
                        </span>
                        
                        <span className="button-span">
                            <Link className="myntist-login-button" to="/">
                                <span className="outer">
                                    <span className="inner">Login</span>
                                </span>
                            </Link>
                            <Link className="myntist-login-button-mobile" to="/login">
                                <span className="outer">
                                    <span className="inner"><FontAwesomeIcon icon={faRightToBracket} className="" /></span>
                                </span>
                            </Link>
                        </span>
                    </Navbar>
                </div>

            </header>
        </>
  )
}
