import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/asset';
import { ENV } from './../../config/config';
import AOS from 'aos'
import 'aos/dist/aos.css';

import './marketplace.css';

const Marketplace = () => {
    AOS.init();

    const goToUrl = (url) => {
        window.open(url);
    } 
    return (
        <div className='marketplace-section'>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div className="marketplace-data">
                        <span className='banner-head'>MYNTIST</span>
                        <h2>NFT MARKETPLACE</h2>
                        <button className='btn-filled transition' onClick={()=>goToUrl(ENV.nftMarketplaceUrl)}><span className="d-block transition">Go to Page</span></button>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='marketplace-img'  data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        <img className='img-fluid' src={Images.marketplaceImg} alt=""  />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Marketplace